//Polyfill
import "core-js";

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;
import jQuery from "jquery";

const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

//ユニクロ風のスライドを復活させる場合はコメントアウトを解除
// document.addEventListener("DOMContentLoaded", function () {
//   const basePath = "/";
//
//   const getSlideIdFromUrl = (url) => url.split("/").filter(Boolean).pop();
//
//   // URLからスライドIDを取得し、対応する要素と初期スライドインデックスを設定
//   const slideId = getSlideIdFromUrl(window.location.pathname.replace(basePath, ""));
//   const targetElement = document.getElementById(slideId);
//   const initialSlideIndex = targetElement ? Array.from(targetElement.parentNode.children).indexOf(targetElement) : 0;
//
//   // Swiperの初期化関数
//   const initializeSwiper = (initialSlideIndex) =>
//     new Swiper(".FrontSwiper", {
//       speed: 500,
//       loop: true,
//       // autoHeight: true,
//       navigation: {
//         nextEl: ".button-next-front",
//         prevEl: ".button-prev-front",
//       },
//       on: {
//         slideChangeTransitionEnd: function () {
//           const activeSlideId = this.slides[this.activeIndex].id;
//           const newUrl = window.location.origin + basePath + activeSlideId + "/";
//           if (window.location.href !== newUrl) {
//             window.location.href = newUrl;
//           }
//         },
//       },
//     });
//
//   // Swiperの初期化
//   const swiper = initializeSwiper(initialSlideIndex);
//
//   // スライドへのリンクにイベントリスナーを設定
//   document.querySelectorAll(".slide-link").forEach((link) => {
//     link.addEventListener("click", function (event) {
//       const viewportHeight = window.innerHeight;
//       const doubledHeight = viewportHeight * 2;
//       if (window.scrollY > doubledHeight) {
//         window.location.href = event.currentTarget.getAttribute("href");
//       } else {
//         event.preventDefault();
//         const targetSlideId = event.currentTarget.dataset.slideId;
//         const targetElement = document.getElementById(targetSlideId);
//
//         // .FrontSwiper 要素が存在する場合のみ処理を実行
//         const FrontSwiperExists = document.querySelector(".FrontSwiper");
//         if (FrontSwiperExists) {
//           if (targetSlideId && targetElement) {
//             const targetIndex = Array.from(targetElement.parentNode.children).indexOf(targetElement);
//             swiper.slideTo(targetIndex, 1000);
//           } else {
//             console.error("Slide ID not found or does not correspond to a swiper slide:", targetSlideId);
//           }
//         } else {
//           // .FrontSwiper 要素が存在しない場合は通常のリンク遷移を実行
//           window.location.href = event.currentTarget.getAttribute("href");
//         }
//       }
//     });
//   });
// });

//店舗詳細スライド
const swiper = new Swiper(".shop-staff-slide", {
  slidesPerView: 1.7,
  spaceBetween: 35,
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  centeredSlides: true,
  breakpoints: {
    768: {
      slidesPerView: 4.9,
      spaceBetween: 50,
    },
  },
});

//施工事例スライド
const swiper = new Swiper(".workSwiper", {
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

//土地スライド
const swiper = new Swiper(".propertySwiper", {
  loop: true,
  slidesPerView: 1.4,
  centeredSlides: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 1.8,
    },
  },
});

//土地スライドサブ
const swiper = new Swiper(".propertyPointSwiper", {
  slidesPerView: 1.5,
  autoHeight: true,
  navigation: {
    nextEl: ".swiper-button-next_1st",
    prevEl: ".swiper-button-prev_1st",
  },
  scrollbar: {
    el: ".swiper-scrollbar_1st",
    draggable: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 4.5,
    },
  },
});

const swiper = new Swiper(".propertyPointSwiper_2", {
  slidesPerView: 1.5,
  navigation: {
    nextEl: ".swiper-button-next_2nd",
    prevEl: ".swiper-button-prev_2nd",
  },
  scrollbar: {
    el: ".swiper-scrollbar_2nd",
    draggable: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 4.5,
    },
  },
});

//イベントのスライドの内のスライド
var bar = document.querySelector(".bar span");
const swiper = new Swiper(".event-mainSlide", {
  slidesPerView: 1.3,
  initialSlide: 1,
  centeredSlides: true,
  allowTouchMove: false,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: ".swiper-button-next_event-main",
    prevEl: ".swiper-button-prev_event-main",
  },
  breakpoints: {
    768: {
      centeredSlides: true,
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  on: {
    slideChangeTransitionStart: function () {
      (bar.style.transitionDuration = "0s"), (bar.style.transform = "scaleX(0)");
    },
    slideChangeTransitionEnd: function () {
      (bar.style.transitionDuration = 3000 + "ms"), (bar.style.transform = "scaleX(1)");
    },
  },
});

//イベントのスタッフオススメ
const swiper = new Swiper(".osusumeSwiper", {
  speed: 500,
  autoplay: {
    delay: 4500,
  },
  slidesPerView: 1.5,
  spaceBetween: 15,
  centeredSlides: true,
  initialSlide: 1,
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
  breakpoints: {
    768: {
      initialSlide: 0,
      slidesPerView: 3.5,
      spaceBetween: 35,
      centeredSlides: false,
    },
  },
});

//イベント一覧下のテキストのスライド
const subSwiper = new Swiper(".card-subSwiper", {
  slidesPerView: 1,
  speed: 500,
  autoplay: {
    delay: 2500,
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 10,
      direction: "vertical",
    },
  },
});

//イベント一覧下のカードのスライド
const mainSwiper = new Swiper(".card-Swiper", {
  slideToClickedSlide: true,
  effect: "cards",
  autoplay: {
    delay: 2500,
  },
  speed: 800,
  grabCursor: true,
  cardsEffect: {
    perSlideOffset: 4, // スライドの間隔（px）
    perSlideRotate: 4, // 2枚目以降のスライドの回転角度
    rotate: true, // 2枚目以降のスライドの回転（あり・なし）
    slideShadows: false, // 2枚目以降のスライドの影（あり・なし）
  },
  // autoplay: {
  //     delay: 2000,
  //     disableOnInteraction: false,
  // },
  navigation: {
    nextEl: ".card-button-next",
    prevEl: ".card-button-prev",
  },
  pagination: {
    el: ".card-pagination",
    clickable: true,
  },
  thumbs: {
    swiper: subSwiper,
  },
});

const subSwiper2 = new Swiper(".card-subSwiper2", {
  slidesPerView: 1,
  speed: 500,
  autoplay: {
    delay: 2500,
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 10,
      direction: "vertical",
    },
  },
});

const mainSwiper2 = new Swiper(".card-Swiper2", {
  slideToClickedSlide: true,
  effect: "cards",
  speed: 800,
  grabCursor: true,
  autoplay: {
    delay: 2500,
  },
  cardsEffect: {
    perSlideOffset: 4, // スライドの間隔（px）
    perSlideRotate: 4, // 2枚目以降のスライドの回転角度
    rotate: true, // 2枚目以降のスライドの回転（あり・なし）
    slideShadows: false, // 2枚目以降のスライドの影（あり・なし）
  },
  // autoplay: {
  //     delay: 2000,
  //     disableOnInteraction: false,
  // },
  navigation: {
    nextEl: ".card-button-next2",
    prevEl: ".card-button-prev2",
  },
  pagination: {
    el: ".card-pagination2",
    clickable: true,
  },
  thumbs: {
    swiper: subSwiper2,
  },
});

//2025年に作成した新規のTOPページのスライドグループ
const topSwiper = new Swiper(".top_mainswiper", {
  effect: "fade",
  speed: 2000,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
});

const top_eventSwiper = new Swiper(".top_eventswiper", {
  initialSlide: 0,
  slidesPerView: 1.4,
  spaceBetween: 40,
  centeredSlides: true,
  breakpoints: {
    768: {
      slidesPerView: 3,
      centeredSlides: false,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const top_brandSwiper = new Swiper(".top_brandswiper", {
  initialSlide: 0,
  slidesPerView: 1.4,
  centeredSlides: true,
  spaceBetween: 20,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  breakpoints: {
    768: {
      slidesPerView: 5,
      centeredSlides: false,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const top_workSwiper1 = new Swiper(".top_workswiper1", {
  loop: true,
  centeredSlides: true,
  loopedSlides: 2,
  slidesPerView: "1.4",
  speed: 3000,
  spaceBetween: 20,
  allowTouchMove: false,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  breakpoints: {
    768: {
      slidesPerView: 4,
    },
  },
});

const top_workSwiper2 = new Swiper(".top_workswiper2", {
  loop: true,
  loopedSlides: 2,
  slidesPerView: "1.4",
  speed: 4000,
  spaceBetween: 20,
  allowTouchMove: false,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
    reverseDirection: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 4,
    },
  },
});


//シンプルなjQueryの処理
$(document).ready(function () {
  // .header-btnがクリックされたときの処理
  $(".header-btn").click(function () {
    // .header-btnと.header-spのopenクラスをトグル（追加/削除）
    $(".header-btn, .header-sp").toggleClass("open");
  });

  // .header-sp内のaタグがクリックされたときの処理
  $(".header-sp a").click(function () {
    // .header-btnと.header-spからopenクラスを削除
    $(".header-btn, .header-sp").removeClass("open");
  });

  $("#is-open").click(function () {
    $("#nav-open,#global-btn").toggleClass("open");
  });

  //サイドバーの挙動
  $(".work-side--main").click(function () {
    $(".work-side--top").toggleClass("open");
  });

  $(".work-side-tag--btn").click(function () {
    $(".work-side--box").removeClass("open");
  });

  $(".work-side--sub").click(function () {
    $(this).toggleClass("open");
    $(this).next(".work-side-list").toggleClass("open");
  });

  //外部リンクの場合はtarget= _blankを追加
  $("a").each(function () {
    // 外部リンクかどうかを判定
    if (this.hostname !== window.location.hostname) {
      // 外部リンクの場合、target属性を _blank に設定
      $(this).attr("target", "_blank");
    }
  });

  // メニューのつけ外し
  // var lastScrollTop = 0;
  // $(window).scroll(function(event){
  //     var st = $(this).scrollTop();
  //     if (st > lastScrollTop){
  //         // 下にスクロールしている場合
  //         $('.global-menu').removeClass('hide');
  //     } else {
  //         // 上にスクロールしている場合
  //         $('.global-menu').addClass('hide');
  //     }
  //     lastScrollTop = st;
  // });

  //スクロールしたらヘッダーの高さを縮める
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      $(".header").addClass("shrink");
    } else {
      $(".header").removeClass("shrink");
    }
  });

  // カテゴリーをクリックした時の処理
  $(".catalog-archive-tag em").click(function () {
    if ($(this).hasClass("active")) {
      return; // アクティブな要素が再度クリックされた場合は何もしない
    }
    $(".catalog-archive-tag em").removeClass("active"); // すべての要素からactiveクラスを削除
    $(this).addClass("active"); // クリックされた要素にactiveクラスを追加

    var selectedId = $(this).attr("id");
    if (selectedId === "all") {
      $(".catalog-archive-box").fadeIn(); // ふわっと表示
    } else {
      $(".catalog-archive-box").fadeOut(); // ふわっと非表示
      $("." + selectedId).fadeIn(); // ふわっと表示
    }
  });

  //アーテージのギャラリー表示
  if ($(window).width() <= 768) {
    $(".artage-gallery-box--bottom").click(function () {
      $(this).toggleClass("active");
    });
  }

  //RTフォームへタイトルと値を反映されるためのコード
  $("[name=calendertime]").change(function () {
    var calender_time = $("[name=calendertime]").val();
    $("#calender_time").val(calender_time);
  });
  $("[name=calenderday]").change(function () {
    var calender_time = $("[name=calenderday]").val();
    $("#calender_day").val(calender_time);
  });

  //Worksの表示の切り替え
  //最初にイベントエリアを表示
  $(".works-insta-box").eq(0).show();
  // タブがクリックされた時の処理
  $(".works-insta-btn--link").click(function () {
    // 全てのworks-insta-boxを非表示にする
    $(".works-insta-box").hide();

    // クリックされたタブに対応するworks-insta-boxを表示する
    const index = $(this).data("target");
    $(".works-insta-box").eq(index).show();
  });
});
